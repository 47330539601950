button.btn.dropdown-toggle.btn-light {
  padding: 17px 20px;
}
.width-100 {
  width: 100%;
}
.width-90 {
  width: 90%;
}
.width-80 {
  width: 80%;
}
.width-70 {
  width: 70%;
}
.width-60 {
  width: 60%;
}
.width-50 {
  width: 50%;
}
.width-40 {
  width: 40%;
}
.width-30 {
  width: 30%;
}
.width-20 {
  width: 20%;
}
.width-15 {
  width: 15%;
}
.width-10 {
  width: 10%;
}
.title {
  font-weight: 700 !important;
  line-height: 1.25 !important;
  font-size: 1.5rem !important;
  color: #fff;
  /*opacity: 0.7;*/
  text-transform: uppercase !important;
  letter-spacing: 0.03rem !important;
}
.box {
  background: #27293d;
  border: 1px solid #3f3f40;
}
.box-value h5 {
  padding: 25px 10px 0px 10px;
  color: #b1b1b5;
  font-weight: 600;
  font-size: 25px;
}
.value-icon h6 {
  padding: 25px 10px 10px 10px;
  color: #b1b1b5;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
}
.value-form h6 {
  padding: 5px 5px 5px 5px;
  color: #b1b1b5;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
  letter-spacing: 0.03rem;
}
.value-form h6 i {
  color: #008000;
}
i.fa.fa-minus-circle {
  color: #ff2929 !important;
}
.value-form button {
  border-radius: 4px;
  padding: 10px 25px;
  margin-top: -10px;
}
.value-form input {
  padding: 5px 5px;
  width: 100%;
}
.value-form input#rd {
  padding: 12px 15px;
}
.value-form input#inlineCheckbox1 {
  padding: 11px;
}
.box-value span {
  font-size: 12px;
}
.float-right {
  float: right;
}
.tile-img-sec img {
  width: 100%;
}
.tile-text-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
}
.tile-main-box {
  position: relative;
}
.tile-detail p {
  color: #ffffff;
}
.value-form h6 input#formFile {
  padding: 5px 10px;
}
.plus-form-sep {
  background: #454869;
  padding: 20px 10px 0px 10px;
  margin: 5px 0px 45px 0px;
  border: 0.5px solid #e6a851;
}
select#inputGroupSelect01 {
  padding: 5px 15px;
}
.byte-date input#rd {
  padding: 5px 15px;
}

.form-check input {
  margin-top: 0px;
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
}
.tile-detail {
  margin-bottom: 4%;
  padding-bottom: 2%;
  border-bottom: 1px solid #4b4747;
}
.individual-searchbar input {
  border-radius: 25px;
}
.individual-searchbar form {
  float: right;
}
.btn.btn-outline-success {
  background-color: #e14eca;
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  border: 1px solid #e14eca;
  color: #ffffff;
  border-radius: 25px;
}

/* indicidual section modal */
.dash-indi-modal .modal-dialog {
  max-width: 1260px;
  margin: 1.75rem auto;
}
.dash-indi-modal-img-tile img {
  width: 380px;
  height: 230px;
  max-width: 100%;
  opacity: 0.4;
}
.dash-indi-modal-texts {
  /*position: absolute;
    top: 4%;
    width: 33%!important;*/
  text-align: center;
  background-image: 'url(brown_test.webp)';
  padding: 10px 70px;
  background-position: center;
  background-size: cover;
  margin: 10px;
  height: 245px;
  max-height: 100%;
}
.dash-indi-modal-texts h2 {
  font-size: 20px;
  margin-top: 15%;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 800;
  color: rgb(255, 255, 255);
  opacity: 0.8;
}
.dash-indi-modal-texts h4 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03rem;
  line-height: 1.2;
  color: rgb(230, 168, 81);
}
.dash-indi-modal-texts h6 {
  font-weight: 800;
  color: rgb(255, 255, 255);
  opacity: 0.8;
  line-height: 1;
  letter-spacing: 0.03rem;
  font-size: 16px;
  margin-top: 1%;
}
.dash-indi-modal-texts span {
  background: rgb(200, 50, 50);
  color: #ffffff;
  padding: 2px 8px;
  text-align: left;
  margin-left: -25px;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.12em;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 4px;
  transition: background 0.25s ease 0s, box-shadow 0.25s ease 0s, opacity 0.25s ease 0s;
  vertical-align: middle;
  border: 0px;
}
.modal-text-details p {
  color: #ffffff;
}
.modal-text-details {
  margin-top: 5% !important;
}
.dash-indi-modal .modal-content {
  background-color: rgb(45, 47, 68) !important;
}
.individual-shorting {
  text-align: center;
  display: flex;
}
.individual-shorting select {
  width: 75%;
  border: 1px solid #606060;
  margin-left: 5%;
}
.individual-shorting p {
  color: #ffffff;
  opacity: 0.7;
  margin-top: 15px;
}
.indi-modal-devider {
  border-bottom: 1px solid;
  margin-bottom: 3%;
  padding-bottom: 1%;
}

.btn.ot-fcs {
  background-color: #f6a5ea;
  background-image: linear-gradient(to bottom left, #f6a5ea, #d5a4f2, #f6a5ea);
  border: 1px solid #f6a5ea !important;
  color: #ffffff;
  /* border-radius: 25px; */
  color: rgb(92, 90, 90);
}

/* Review Table */

.review-edit-table .modal-dialog {
  max-width: 930px;
}
tbody.review-edit-click tr:hover {
  cursor: pointer;
  background: #1e1e2e;
}

/* MUI form CSS */
.requiredCss {
  border: 2px solid #ff3031 !important;
  height: 57px;
  max-height: 100%;
  border-radius: 15px !important;
}

.userMandatoryForm {
  background-color: #000000ed;
}

.userMendSec .verifyOtp {
  border: 1px solid rgb(227, 204, 128);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px !important;
  border-radius: 50px !important;
  margin: 17px 10px 0px 0px !important;
  width: 100%;
  background: rgb(227, 204, 128);
  color: #000000 !important;
}

.userMendSec .verifyOtp:active,
.userMendSec .verifyOtp:focus,
.userMendSec .verifyOtp:hover {
  border: 1px solid rgb(227, 204, 128) !important;
  background: rgb(227, 204, 128);
  color: #000000 !important;
}

.regProceedButton {
  margin: 0px 10px 10px 0px;
  margin-bottom: 15px;
  margin-top: 0%;
  text-transform: uppercase;
  vertical-align: middle;
  letter-spacing: 0.03rem;
  font-size: 18px;
  padding: 10px 70px;
  border-radius: 8px;
  background: #e32652;
  border: 0;
  color: #ffffff;
  font-weight: 600;
}

.regProceedButton:hover {
  background: #c83232;
  border: 0px !important;
  color: #ffffff;
}

.regProceedButton:active,
.regProceedButton:focus {
  background: #ffffff;
  border: 1px solid #ffffff !important;
  color: #000000;
}

.mendForm .studentParent p {
  letter-spacing: 0.05rem;
  font-size: 25px;
  opacity: 0.8;
  color: #ffffff;
}

.mendForm .studentParent {
  padding: 2% 0% 5% 0%;
}

.mendForm .studentParent .student,
.mendForm .studentParent .parent {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 10px 50px;
  border-radius: 0px;
  width: 50%;
}

.mendForm .studentParent .student {
  background: #e32652;
  color: #ffffff;
  border: 1px solid #e32652;
}

.mendForm .studentParent .parent {
  background: #ffffff;
  color: #000000;
  border: 1px solid #ffffff;
}

.mendForm .studentParent .student:hover,
.mendForm .studentParent .parent:hover {
  background: #e32652;
  color: #ffffff;
  border: 1px solid #e32652 !important;
}

.verifyOtp {
  border: 1px solid rgb(227, 204, 128);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px !important;
  border-radius: 50px;
  margin: 16px 10px 0px 0px;
  width: 100%;
  background: rgb(227, 204, 128);
  color: #000000;
  border: 1px solid #323644;
}

.verifyOtp a {
  color: rgb(227, 204, 128);
}

.verifyOtp:hover {
  border: 1px solid rgb(227, 204, 128) !important;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px !important;
  border-radius: 50px;
  margin: 16px 10px 0px 0px;
  width: 100%;
  background: rgb(227, 204, 128) !important;
  color: #000000 !important;
  border: 1px solid #323644;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .regProceedButton {
    border-radius: 5px;
  }

  .mendForm .studentParent p {
    font-size: 25px;
  }

  .mendForm .studentParent .student,
  .mendForm .studentParent .parent {
    font-size: 18px;
    padding: 8px 10px;
  }

  .userMendSec .verifyOtp {
    margin-bottom: 5%;
    color: rgb(227, 204, 128);
    margin-top: 0%;
    background-color: #261e35;
    border: #261e35;
  }

  .regProceedButton {
    width: 100%;
  }
}

.react_autosuggest__input_error input {
  border: 2px solid red !important;
}

.css-919eu4 {
  background-color: rgb(0 0 0 / 91%) !important;
  /* -webkit-tap-highlight-color: transparent;
  z-index: -1; */
}

.add-master-custom .makeStyles-errorAutoText-2 {
  margin: -17px 0px 0px 0px !important;
}