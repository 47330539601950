.PhoneInput > :first-child {
  border: 1px solid rgb(255 255 255 / 48%);
  border-right: 0px !important;
  text-align: center;
  vertical-align: middle;
  padding-left: 10px;
  height: 56px;
  border-radius: 3px;
  margin: 0px 0px 0px 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.PhoneInput,
.PhoneInput_Error {
  /* margin-bottom: 10px; */
}

.PhoneInput input,
.PhoneInput_Error input {
  color: #ffffff !important;
}

.PhoneInput label,
.PhoneInput_Error label {
  color: #ffffff !important;
}

.PhoneInput legend,
.PhoneInput_Error legend {
  color: #ffffff !important;
}

.PhoneInput > :nth-child(2) fieldset {
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.PhoneInput:focus > :nth-child(2) fieldset,
.PhoneInput:focus > :first-child,
.PhoneInput:focus-within > :nth-child(2) fieldset,
.PhoneInput:focus-within > :first-child,
.PhoneInput:focus-visible > :nth-child(2) fieldset,
.PhoneInput:focus-visible > :first-child {
  border-width: 2px;
  /* border-color: #1976d2; */
  border-color: rgb(255 255 255 / 48%);
}

.PhoneInput_Error > :first-child {
  border: 1px solid #d32f2f !important;
  border-right: 0px !important;
  text-align: center;
  vertical-align: middle;
  padding-left: 10px;
  height: 56px;
  border-radius: 3px;
  margin: 0px 0px 0px 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.PhoneInput_Error > :nth-child(2) fieldset {
  border-left: 0px;
  border-color: #d32f2f !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.PhoneInput_Error > :nth-child(2) label {
  color: #d32f2f !important;
}

.PhoneInput_Error:focus > :nth-child(2) fieldset,
.PhoneInput_Error:focus > :first-child,
.PhoneInput_Error:focus-within > :nth-child(2) fieldset,
.PhoneInput_Error:focus-within > :first-child,
.PhoneInput_Error:focus-visible > :nth-child(2) fieldset,
.PhoneInput_Error:focus-visible > :first-child {
  border-width: 2px !important;
  /* height: 56px; */
  /* border-color: #1976d2; */
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: rgb(255 255 255 / 54%) !important;
}
.css-1636szt {
  color: rgb(255 255 255 / 54%) !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #ffffffc9 !important;
}
.css-1x5jdmq {
  color: #ffffffc9 !important;
}
/* New css for MUI */
.MuiFormLabel-root-MuiInputLabel-root {
  color: #ffffff9e !important;
}
/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline { */
.MuiOutlinedInput-notchedOutline {
  border-color: rgb(255 255 255 / 41%) !important;
  padding: 10px 0px !important;
}
.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
  margin-bottom: 20px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  /* background: #2d2f44; */
}
label[data-shrink='false'] + .MuiInputBase-formControl .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  /* background: #2d2f44; */
}
.MuiFormControl-root.MuiFormControl-fullWidth {
  margin-bottom: 10px;
}
.MuiFormControl-root-MuiTextField-root fieldset {
  /* background: #2d2f44; */
  /* z-index: -1; */
}
.css-15edzf2 {
  background-color: #2d2f44!important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(0 0 0 / 82%)!important;
}
.css-1ppgp8t .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: #ffffffc2 !important;
}
.css-16j4c3r-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: #ffffffc2 !important;
}
.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  color: #ffffffc2 !important;
}
.css-u36398 {
  color: #ffffffc2 !important;
}
fieldset.MuiOutlinedInput-notchedOutline span {
  color: #ffffff !important;
}
/* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root { */
.MuiInputBase-root-MuiOutlinedInput-root {
  color: #ffffff;
}
/* input {
  color: #ffffff !important;
} */
.MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(208 208 208) !important;
}
.MuiFormControl-root.MuiFormControl-fullWidth label {
  color: #ffffff !important;
}
.PhoneInput > :first-child {
  background: #2d2f44;
  border-right: 1px solid #ffffff;
}
/* .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon { */
.MuiSvgIcon-root-MuiSelect-icon {
  color: rgb(255 255 255 / 54%) !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  background: #2d2f44;
  color: #ffffffd4!important;

}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-135g1k9-MuiButtonBase-root-MuiButton-root {
  margin-right: 10px;
  margin-left: 10px;
}