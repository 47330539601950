.btn-event {
  background-color: #e14eca;
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  background-size:210% 210%;
  background-position: 100% 0;
  padding: 10px;
  text-decoration: none;
  color: #FFF;
  font-weight: 600;
  margin: auto;
}
